import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class TipoCuentaService {

    apiURL = environment.apiProterm;

    constructor(private http: HttpClient) {

    }

    public getListaTipoCuenta() {
        return this.http.get(this.apiURL + 'tipocuentas/list');
    }

    public setNuevoTipoCuenta(obj) {
      return this.http.post(`${this.apiURL}tipocuentas/save`, obj);
    }
  
    public deleteTipoCuenta(id) {
      return this.http.delete(this.apiURL + 'tipocuentas/delete/' + id);
    }


}
