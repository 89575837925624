import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class BancoService {

    apiURL = environment.apiProterm;

    constructor(private http: HttpClient) {

    }

    public getListaBanco() {
        return this.http.get(this.apiURL + 'bancos/list');
    }

    public setNuevoBanco(banco) {
      return this.http.post(`${this.apiURL}bancos/save`, banco);
    }
  
    public deleteBanco(bancoId) {
      return this.http.delete(this.apiURL + 'bancos/delete/' + bancoId);
    }

}
