import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class OficinaService {

    apiURL = environment.apiProterm;

    constructor(private http: HttpClient) {

    }

    public getListaOficinas() {
        return this.http.get(this.apiURL + 'oficinas/list');
    }

    public getListaOficinasPorSucursal(id: Number) {
        return this.http.get(this.apiURL + 'oficinas/list/sucursal/'+id);
    }

}
