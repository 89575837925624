import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class PaisService {

    apiURL = environment.apiProterm;

    constructor(private http: HttpClient) {

    }

    public getListaPaises() {
        return this.http.get(this.apiURL + 'paises/list');
    }

}
