import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class ProvinciaService {

    apiURL = environment.apiProterm;

    constructor(private http: HttpClient) {

    }

    public getListaProvincias() {
        return this.http.get(this.apiURL + 'provincias/list');
    }

    public getListaProvinciasPorRegion(id:number) {
        return this.http.get(this.apiURL + 'provincias/list/region'+ id);
    }


}
