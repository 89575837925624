export const es_language = {
    processing: 'Procesando...',
    lengthMenu: 'Mostrar <select>' +
        '<option value="10">10</option>' +
        '<option value="20">20</option>' +
        '<option value="30">30</option>' +
        '<option value="40">40</option>' +
        '<option value="50">50</option>' +
        '<option value="-1">Todos</option>' +
        '</select> registros',
    zeroRecords: 'No se encontraron resultados',
    emptyTable: 'Ningún dato disponible en esta tabla',
    info: '',
    // 'sInfoEmpty':      'Mostrando del 0 al 0 de un total de 0 registros',
    infoFiltered:   '',
    infoPostFix: '',
    search: 'Buscar:',
    url: '',
    // infoThousands: ',',
    loadingRecords: 'Por favor espere - cargando...',
    aria: {
        sortAscending: ': Activar para ordenar la columna de manera ascendente',
        sortDescending: ': Activar para ordenar la columna de manera descendente'
    },
    paginate: {
        first: 'Primero',
        last: 'Último',
        next: 'Siguiente <i class="ti-angle-double-right"></i>',
        previous: '<i class="ti-angle-double-left"></i> Anterior'
    },
};
