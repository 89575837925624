import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {Especialidad} from '../models/Especialidad.model';

@Injectable({
  providedIn: 'root'
})

export class EspecialidadService {

  apiURL = environment.apiProterm;

  constructor(private http: HttpClient) {

  }

  public getListaEspecialidades() {
    return this.http.get(this.apiURL + 'especialidades/list');
  }

  public setNuevaEspecialidad(especialidad: Especialidad) {
    return this.http.post(this.apiURL + 'especialidades/', especialidad);
  }

  public updateEspecialidad(especialidad: Especialidad) {
    return this.http.put(`${this.apiURL}especialidades/${especialidad.id}`, especialidad);
  }

}
