import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class RegionService {

    apiURL = environment.apiProterm;

    constructor(private http: HttpClient) {

    }

    public getListaRegiones() {
        return this.http.get(this.apiURL + 'regiones/list');
    }

}
