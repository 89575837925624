import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})

export class CargoService {

    apiURL = environment.apiProterm;

    constructor(private http: HttpClient) {

    }

    public getListaCargos() {
        return this.http.get(this.apiURL + 'cargos/list');
    }

    public setNuevoCargo(cargo) {
      return this.http.post(`${this.apiURL}cargos/save`, cargo);
    }
  
    public deleteCargo(id) {
      return this.http.delete(this.apiURL + 'cargos/delete/' + id);
    }

}
